.background_root {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #e8e7e7;
}

.background_vertical {
  position: absolute;
  width: 2px;
  height: 100%;
  background: #e3e2e2;
}

.background_horizontal {
  position: absolute;
  width: 100%;
  height: 2px;
  background: #e3e2e2;
}

.background_child-container {
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
}
