.shake0 {
  animation: shake 0.8s;
}

.shake1 {
  animation: shake1 0.8s;
}

@keyframes shake1 {
  0% {
    transform: translateY(10px);
  }
  5% {
    transform: translateX(10px);
  }
  10% {
    transform: translateY(-5px);
  }
  15% {
    transform: translateX(-5px);
  }
  20% {
    transform: translateY(2px);
  }
  25% {
    transform: translateX(2px);
  }
}

@keyframes shake {
  0% {
    transform: translateY(10px);
  }
  5% {
    transform: translateX(10px);
  }
  10% {
    transform: translateY(-5px);
  }
  15% {
    transform: translateX(-5px);
  }
  20% {
    transform: translateY(2px);
  }
  25% {
    transform: translateX(2px);
  }
}

.game_manager-qr {
  position: absolute;
  top: 5%;
  right: 0;
  width: 342px;
  height: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: game_manager-qr_in 1s;
}

.game_manager-qr_body {
  margin-left: 80px;
  color: white;
  margin-bottom: 10px;
  text-align: end;
}

@keyframes game_manager-qr_in {
  0% {
    transform: translateX(100%);
  }
  100% {
  }
}
