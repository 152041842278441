.title {
  background: url('../../assets/magicblocks/playingTitle.png');
  width: 784px;
  height: 65px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 20px;
  animation: pieces-background-enter 0.7s;
}

.announcement {
  position: absolute;
  width: 800px;
  height: 800px;
  color: white;
  font-size: 48px;
  white-space: pre;
  z-index: 100000;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.announcemnetDisappear {
  animation: announcemnet-disappear 4s forwards;
}

.announcemnetForever {
  animation: announcemnet-forever 2s;
}

@keyframes announcemnet-disappear {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }
  75% {
    opacity: 1;
    transform: scale(0.9);
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes announcemnet-forever {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
}

.selectedCellBody {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: cell-enter-body 0.3s ease-in;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
}

@keyframes cell-enter-body {
  0% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.selectedCellMark {
  width: 0px;
  height: 0px;
  z-index: 10;
}

@keyframes mark-enter {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.piecesBackground {
  background: url('../../assets/magicblocks/piecesBackground.png');
  width: 380px;
  height: 380px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: -45px;
  top: 40px;
  animation: pieces-background-enter 0.7s;
}

@keyframes pieces-background-enter {
  0% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.xContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: cell-enter-body 0.3s forwards;
  transform: scale(0);
  z-index: 10000;
}

.x0 {
  transform: rotate(45deg);
  position: absolute;
}

.x1 {
  transform: rotate(-45deg);
  position: absolute;
}

.gameGridRoot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: #c7c6c6 0px 0px 5px 25px;
  outline: 1px solid black;
  animation: game-grid-enter 0.7s 0.7s forwards ease-in-out;
  opacity: 0;
}

@keyframes game-grid-enter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.col {
  position: absolute;
  width: 0.5px;
  box-sizing: border-box;
  background: #bfbfbf;
  top: 0;
}

.row {
  left: 0;
  position: absolute;
  background: #bfbfbf;
  height: 0.5px;
}

.firstAndLastCol {
  border: 1px solid black;
}

.firstAndLastRow {
  background: black;
  height: 1px;
}

.animationCell {
  transform: scale(0);
  border-radius: 50%;
  position: absolute;
  z-index: 10000;
}

.animationCellAnimate-1 {
  animation: cell-selected-1 0.5s cubic-bezier(0, 0.54, 0.55, 0.98) forwards;
}

.animationCellAnimate-2 {
  animation: cell-selected-2 0.5s cubic-bezier(0, 0.54, 0.55, 0.98) forwards;
}

.animationCellAnimate {
  animation: cell-selected-3 0.5s cubic-bezier(0, 0.54, 0.55, 0.98) forwards;
}

@keyframes cell-selected-1 {
  0% {
  }
  50% {
    transform: scale(1);
  }
  100% {
  }
}

@keyframes cell-selected-2 {
  0% {
  }
  50% {
    transform: scale(0.5);
  }
  100% {
  }
}

@keyframes cell-selected-3 {
  0% {
  }
  50% {
    transform: scale(0.3);
  }
  100% {
  }
}

@keyframes cell-enter {
  0% {
    transform: scale(1.7);
    opacity: 0;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hover-cell-enter {
  0% {
    transform: scale(1.8);
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}

.hoverCell {
  animation: hover-cell-enter 0.3s forwards;
  transition: 0.3s;
  z-index: 1000;
  position: absolute;
  opacity: 0;
}

.highlightCell {
  border-radius: 50%;
  animation: cell-enter 0.3s forwards, highlightCell-boxShadow 5s infinite;
  transition: 0.3s;
  z-index: 90;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  box-sizing: border-box;
  border: 1px solid transparent;
}

@keyframes highlightCell-boxShadow {
  0% {
    box-shadow: 0px 0px 5px 0px;
  }
  10% {
    box-shadow: 0px 0px 5px 5px;
  }
  25% {
    box-shadow: 0px 0px 0px 0px;
  }
}

.tutorialTitle {
  z-index: 100000;
  position: absolute;
  font-size: 42px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: tutorial-title-enter 1s;
}

@keyframes tutorial-title-enter {
  0% {
    transform: scaleY(0);
  }
  100% {
  }
}
