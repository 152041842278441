@font-face {
  font-family: 'blockbuster';
  src: url('assets/magicblocks/Blockbuster.ttf') format('truetype');
}

@font-face {
  font-family: 'trebuc';
  font-style: normal;
  font-weight: normal;
  src: url('assets/magicblocks/trebuc.woff') format('woff');
}

.root-magicblocks-display {
  letter-spacing: 5px;
  color: white;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.root-magicblocks-display {
  color: white;
  margin: 0;
  font-family: 'trebuc', sans-serif;
  letter-spacing: 5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 36px;
  -webkit-tap-highlight-color: transparent;
}

.root-magicblocks-display * {
  letter-spacing: 5px;
  font-family: 'trebuc', sans-serif;
}
