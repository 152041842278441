/* define a variable for animation duration*/
:root {
  --animation-duration: 1s;
}

.root {
  width: 100%;
  height: 100%;
  display: flex;
  color: black;
  flex-direction: column;
  align-items: center;
  background-image: url('../../assets/magicblocks/tutorialBg.png');
}

.piece {
  width: 60px;
  height: 60px;
  background-image: url('../../assets/magicblocks/rect.png');
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(-90deg) translateX(15px) translateY(15px);
}

.title {
  background-image: url('../../assets/magicblocks/playingTitle.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 784px;
  height: 65px;
  margin-top: 40px;
  margin-bottom: 40px;
  animation: tile-container-enter var(--animation-duration) forwards;
  z-index: 100;
}

.subtitle {
  margin-top: 16px;
  font-family: trebuc;
  letter-spacing: 5px;
  font-size: 32px;
  opacity: 0;
  animation: tile-container-enter var(--animation-duration) 1.5s forwards;
}

.subtitle2 {
  margin-top: 32px;
  opacity: 0;
  letter-spacing: 5px;
  font-size: 32px;
  display: flex;
  animation: tile-container-enter var(--animation-duration) forwards;
}

.tilesContainer {
  display: flex;
  justify-content: center;
}

.tileContainer {
  letter-spacing: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 32px;
  width: 330px;
  margin-right: 50px;
  margin-left: 50px;
}

.cta {
  z-index: 100;
  opacity: 0;
  animation: tile-container-enter var(--animation-duration) forwards;
  font-size: 24px;
  position: absolute;
  bottom: 20px;
  width: 600px;
  height: 150px;
  justify-content: center;
  display: flex;
  align-items: center;
  background-image: url('../../assets/magicblocks/button.png');
  background-size: contain;
  background-repeat: no-repeat;
}

@keyframes tile-container-enter {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.bodyPartChildContainer {
  opacity: 0;
  animation: tile-container-enter var(--animation-duration) forwards;
}

.imageBorder {
  /*box-shadow: 0 0 5px 15px rgba(200,200,200, 1);*/
  width: 330px;
  height: 330px;
  margin-bottom: 20px;
  margin-top: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  animation: tile-container-enter var(--animation-duration) forwards;
}

.illegalMove {
  background-image: url('../../assets/magicblocks/illegalMove.png');
}

.legalMove {
  background-image: url('../../assets/magicblocks/legalMove.png');
}

.legalMoveTouchingEnemy {
  background-image: url('../../assets/magicblocks/legalMoveTouchingEnemy.png');
}

.howToWin {
  background-image: url('../../assets/magicblocks/howToWin.png');
}

.icon {
  margin-bottom: 16px;
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  animation: icon-enter var(--animation-duration) forwards;
}

@keyframes icon-enter {
  0% {
    transform: scale(2) rotate(45deg);
  }
  100% {
    opacity: 1;
  }
}

.arrow {
  background-image: url('../../assets/magicblocks/arrow.png');
}

.rect {
  background-image: url('../../assets/magicblocks/rect.png');
}

.plus {
  background-image: url('../../assets/magicblocks/plus.png');
}

.tick {
  background-image: url('../../assets/magicblocks/tick.png');
}

.x {
  background-image: url('../../assets/magicblocks/x.png');
}
